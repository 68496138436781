import React from 'react';
import { sectionList } from '../../constants/queryFragments';
import loadable from '@loadable/component';
import * as Styled from './slpWelcomeSectionListStyles';

const SlpWelcomeSectionList = ({ sectionData }) => {
  return (
    <Styled.SLPMainContainer>
      <Styled.InnerBody>
        <Styled.HeadingContainer>
          {sectionData?.header?.trim() && (
            <Styled.HeaderH2 textAlign="left">
              {sectionData?.header}
            </Styled.HeaderH2>
          )}
        </Styled.HeadingContainer>
        {sectionData && (
          <>
            <Styled.RowStyle className="g-0">
              {sectionData?.list &&
                sectionData?.list?.map((section, index) => {
                  if (section?.type && sectionList?.includes(section?.type)) {
                    const SectionComponent = loadable(() =>
                      import(
                        `../../components/${section?.type}/${section?.type}`
                      )
                    );
                    return (
                      <Styled.ColumnThird
                        key={index}
                        xs={12}
                        lg={4}
                        data-aos="fade-up"
                        data-aos-delay={index * 200}
                        data-aos-once="true"
                        data-aos-duration="1000"
                      >
                        <SectionComponent key={index} sectionData={section} />
                      </Styled.ColumnThird>
                    );
                  }
                })}
            </Styled.RowStyle>
          </>
        )}
      </Styled.InnerBody>
    </Styled.SLPMainContainer>
  );
};
export default SlpWelcomeSectionList;
