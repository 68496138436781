import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

export const SLPMainContainer = styled.div`
  color: #6e2c88;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.02em;
  width: 100%;
  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

export const InnerBody = styled.div`
  width: 700px;
  margin: 0 auto;
  padding: 60px 0;
  @media (max-width: 991px) {
    width: 100%;
    padding: 35px;
  }
`;
export const HeaderH2 = styled.h2`
  text-align: center;
  font-family: 'Figtree-Regular', arial, sans-serif;
  position: relative;
  color: #6e2c88;
  font-size: 36px;
  line-height: 46px;
  margin: 0 0 35px 0;
  padding: 0;
  font-weight: normal !important;
  @media (max-width: 991px) {
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 33px;
  }
`;

export const Columns = styled.div`
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;

export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: rgba(0, 0, 0, 0.15);
  margin: 30px 0;
  clear: both;
  @media (max-width: 991px) {
    margin: 25px 0;
  }
`;
export const ColumnThird = styled(Col)`
  width: 30%;
  text-align: center;
  position: relative;
  border-right: 1px solid #ededed;
  padding: 7px 25px 3px 25px !important;
  :last-child {
    border: none;
  }
  p {
    margin: 0 !important;
  }
  @media (max-width: 991px) {
    padding: 15px 0 !important;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    border: none;
    border-bottom: 1px solid #ededed;
    ul:first-child {
      margin-top: -30px !important;
    }
  }
`;

export const HeadingContainer = styled.div`
  width: 580px;
  margin: 0 auto;
  text-align: center;
  letter-spacing: 0.02em;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const RowStyle = styled(Row)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  @media (max-width: 991px) {
    display: block;
  }
`;
